<template>
  <v-container id="regular-tables-view" fluid tag="section">
    <v-alert
      v-if="alert.visible"
      color="primary"
      dark
      icon="fa-regular fa-hexagon-exclamation"
      border="left"
      prominent
    >
      {{ alert.description }}
      <v-btn
        v-if="alert.button.visible"
        color="secondary"
        elevation="2"
        @click="alert.button.cl"
      >
        <i v-if="alert.button.icon" :class="alert.button.icon"></i>
        {{ alert.button.text }}
      </v-btn>
    </v-alert>
    <view-intro class="text-start" heading="Bestelling"/>
    <div class="mb-4">
      <v-btn
        color="primary"
        elevation="2"
        outlined
        @click="$router.go(-1)"
      >&lArr; Terug
      </v-btn>
      <v-btn
        class="ml-4 "
        elevation="2"
        color="green"
        outlined
        @click="dialog.visible = true"
      >
        <i class="fa-regular fa-hashtag fa-xl mr-3"></i>
        <p class="fw-bold" style="margin: auto 0;">
          code geven
        </p>
      </v-btn>
      <p v-if="orderGroup.code" class="d-inline ml-2">
        <strong class="text-mono">{{ orderGroup.code }}</strong>
      </p>
    </div>
    <div v-if="loading" class="d-flex justify-content-center" style="margin-top: 30vh;">
      <v-progress-circular
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <div v-else>
      <material-card
        class="mt-10 elevation-1"
        icon="fa-regular fa-info"
        icon-small
        :title="`Bestelling informatie`"
        color="accent"
      >
        <v-card-text>
          <v-alert
            outlined
            shaped
            text
            type="success"
            v-if="transaction.paid"
            icon="fa-regular fa-money-bill-wave"
          >Deze bestelling is betaald
          </v-alert>
          <v-alert
            v-else
            outlined
            shaped
            text
            type="error"
            icon="fa-regular fa-money-bill-wave"
          >Deze bestelling is niet betaald ({{ transaction.stateName }})
          </v-alert>
          <v-alert
            outlined
            shaped
            text
            type="warning"
            v-if="orderGroup.status === 'open'"
          >Deze bestelling is open
          </v-alert>
          <v-alert
            outlined
            shaped
            text
            v-else-if="orderGroup.status === 'closed'"
            type="success"
            icon="fa-regular fa-truck"
          >Deze bestelling is afgelevert
          </v-alert>
          <v-alert
            outlined
            shaped
            text
            v-else-if="orderGroup.status === 'interrupted'"
            type="error"
          >Deze bestelling is onderbroken
          </v-alert>
          <v-alert
            outlined
            text
            v-if="orderGroup.status === 'open' &&  orderGroup.timeBlockStatus === 'specific'"
          >Ophalen {{ calculatePickTime(orderGroup.timeBlock) }}
          </v-alert>
          <div style="display: flex; gap:15px; flex-wrap: wrap">
            <v-card
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="mb-2" style="display: flex; align-items: center;">
                    <i class="fa-regular fa-user mr-3 fa-2x"></i>
                    <div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{ user.user.name }}
                      </v-list-item-title>
                      <div class="d-flex flex-column">
                        <a :href="`mailto:${user.user.email}`" class="text-overline mb-2">
                          {{ user.user.email }}
                        </a>
                        <a v-if="user.user.phoneNumber" :href="`tel:${user.user.phoneNumber}`" class="text-overline">
                          +31 {{ user.user.phoneNumber }}
                        </a>
                      </div>
                    </div>
                  </div>
                  <v-list-item-subtitle> {{ user.address.formatted }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <v-btn
                  outlined
                  rounded
                  text
                  @click="goToUser"
                  class="pl-4 pr-4"
                >
                  <i class="fa-regular fa-up-right-from-square mr-3 fa-1x"></i>
                  Gebruiker bekijken
                </v-btn>
              </v-card-actions>
            </v-card>
            <v-card
              max-width="344"
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1" style="display: flex; align-items: center">
                    <i class="fa-regular fa-money-bill mr-3 fa-2x"></i>
                    {{ '€ ' + formatPrice(orderGroup.totalPrice) }} euro
                  </v-list-item-title>
                  <div class="text-overline mb-4">
                    Besteld op {{ $auth.createFulldate(orderGroup.date) }}
                  </div>
                  <v-card-actions>
                    <v-btn
                      v-if="false"
                      outlined
                      rounded
                      text
                      @click=""
                      class="pl-4 pr-4"
                    >
                      <i class="fa-regular fa-up-right-from-square mr-3 fa-1x"></i>
                      Details bekijken
                    </v-btn>
                  </v-card-actions>
                </v-list-item-content>
              </v-list-item>
            </v-card>

            <v-card
              outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <v-list-item-title class="text-h5 mb-1" style="display: flex; align-items: center">
                    <i class="fa-regular fa-triangle-exclamation mr-3 fa-2x"></i>
                    {{ orderGroup.status }} status
                  </v-list-item-title>
                  <!--                <div class="text-overline mb-4">-->
                  <!--                  Besteld op {{ $auth.createFulldate(orderGroup.date)}}-->
                  <!--                </div>-->
                  <v-list-item-subtitle>Besteld op {{ $auth.createFulldate(orderGroup.date) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <div style="display: flex; flex-wrap: wrap; gap: 15px">
                  <v-btn
                    v-if="orderGroup.status !== 'closed'"
                    color="green"
                    class="pl-4 pr-4"
                    rounded
                    outlined
                    @click="setAsClosed"
                  >
                    <i class="fa-regular fa-circle-check mr-3 fa-2x"></i>
                    Zet als Afgelevert
                  </v-btn>
                  <v-btn
                    v-if="orderGroup.status !== 'open'"
                    color="warning"
                    class="pl-4 pr-4"
                    rounded
                    @click="setAsOpen"
                  >
                    <i class="fa-regular fa-circle-exclamation mr-3 fa-2x"></i>
                    Zet als open
                  </v-btn>
                  <v-btn
                    v-if="orderGroup.status !== 'interrupted'"
                    outlined
                    color="error"
                    class="pl-4 pr-4"
                    rounded
                    @click="setAsInterrupted"
                  >
                    <i class="fa-regular fa-circle-xmark mr-3 fa-2x"></i>
                    Zet als onderbroken
                  </v-btn>

                </div>

              </v-card-actions>

            </v-card>

            <v-card
              max-width="344"
              outlined
              v-if="timeBlockDateTime"
            >
              <v-list-item three-line>
                <v-list-item-content v-if="orderGroup.timeBlockStatus === 'specific'">
                  <div class="mb-2" style="display: flex; align-items: center;">
                    <i class="fa-regular fa-clock mr-3 fa-2x"></i>
                    <div>
                      <v-list-item-title class="text-h5 mb-1">
                        {{ timeBlockDate }}
                      </v-list-item-title>
                    </div>
                  </div>
                  <v-list-item-subtitle class="fw-bold">
                    {{ timeBlockDateTime }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content v-if="orderGroup.timeBlockStatus === 'fast'">
                  <div class="mb-2" style="display: flex; align-items: center;">
                    <i class="fa-regular fa-truck-fast mr-3 fa-2x"></i>
                    <div>
                      <v-list-item-title class="text-h5 mb-1">
                        Zo snel mogenlijk
                      </v-list-item-title>
                    </div>
                  </div>
                  <v-list-item-subtitle class="fw-bold">
                    Zo snel mogenlijk ophalen
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-card-actions>
                <v-btn
                  v-if="orderGroup.timeBlockStatus === 'specific'"
                  outlined
                  rounded
                  text
                  :to="`/timeblock/${orderGroup.timeBlock.id}`"
                  class="pl-4 pr-4"
                >
                  <i class="fa-regular fa-up-right-from-square mr-3 fa-1x"></i>
                  Tijdsblok bekijken
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </v-card-text>
      </material-card>
      <material-card
        v-if="orderGroup.address"
        :heading="orderGroup.address.formatted"
        color="accent"
        class="mt-15"
      >
        <v-card-text>
          <v-sheet>
            <iframe
              :loading="loading"
              loading-text="Laden even geduld aub"
              v-if="clientCoords"
              height="450"
              style="border:0"
              width="100%"
              loading="lazy"
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              :src="`https://www.google.com/maps/embed/v1/directions?origin=${clientCoords.lat}, ${clientCoords.long}&destination=${orderGroup.address.formatted}&key=AIzaSyAWKzmZ1tcpbkrpjiQTPegezcLjrRTOaoo&language=nl`"
            >
            </iframe>
            <iframe
              v-else
              height="450"
              style="border:0"
              width="100%"
              loading="lazy"
              allowfullscreen
              referrerpolicy="no-referrer-when-downgrade"
              :src="`https://maps.google.com/maps?q=${orderGroup.address.formatted}&t=&z=13&ie=UTF8&iwloc=&output=embed`"
            >
            </iframe>
          </v-sheet>
        </v-card-text>
      </material-card>
      <material-card
        class="mt-6 elevation-1 mt-16"
        icon="fa-regular fa-box"
        icon-small
        title="Orders"
        color="accent"
      >
        <v-data-table
          :loading="loading"
          loading-text="Laden even geduld aub"
          :headers="headers"
          :items="orderGroup.orders"
          :items-per-page="10"
        ></v-data-table>
      </material-card>
      <div class="mt-4">
        <v-btn
          color="primary"
          elevation="2"
          outlined
          @click="$router.go(-1)"
        >&lArr; Terug
        </v-btn>
      </div>
      <v-dialog
        v-model="dialog.visible"
        persistent
        max-width="600px"
      >
        <v-card>
          <v-card-title>
            <span class="text-h3">Order code toevoegen</span>
          </v-card-title>
          <vue-form-generator ref="createNewForm" class="px-5 mt-3" :schema="dialog.schema" :model="dialog.model"
                              :options="formOptions"
          ></vue-form-generator>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog.visible = false;"
            >Annuleren
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="setASheetCode"
              :loading="dialog.loadingBtn"
            >Opslaan
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
  import VueFormGenerator from 'vue-form-generator'

  export default {
    name: 'BestellingenDetail',
    data () {
      const { groupId } = this.$route.params
      if (!groupId) return this.$router.push('/notfound')
      this.$auth.getOrderGroup(groupId).then(async (orderGroup) => {
        if (!orderGroup) {
          this.$swal({
            icon: 'error',
            title: 'Deze bestelling is niet gevonden',
            confirmButtonText: 'Terug',
          }).then(() => {
            window.history.go(-1)
          })
          this.alert.visible = true
          this.alert.description = `Deze bestellingen bestaat niet`
          return
        }
        orderGroup.orders = orderGroup.orders.map(order => {
          if (order.id === 'ZENDING_KOSTEN') {
            order.count = '--'
            order.section = '--'
          }
          return order
        })
        this.orderGroup = orderGroup
        this.OLDCOPY_orderGroup = Object.assign({}, orderGroup);
        this.dialog.model.code = orderGroup.code
        this.orderGroup.orders = this.$filter.filterProducts(this.orderGroup.orders)
        this.user = await this.$auth.getStoreUser(orderGroup.userUid)

        this.date = this.$auth.createFulldate(orderGroup)

        this.transaction = await this.$auth.getTransactionStatus(orderGroup.transactionId)
        await this.calculatePickTime()
      }).catch(err => {
        this.alert.visible = true
        this.alert.description = `Er is iets mis gegaan error: ${err} Neem graag contact met beheerder`
      }).finally(() => {
        this.loading = false
      })
      return {
        timeBlockDate: null,
        timeBlockDateTime: null,
        OLDCOPY_orderGroup: null,
        transaction: null,
        dialog: {
          visible: false,
          loadingBtn: false,
          schema: {
            fields: [
              {
                type: 'input',
                inputType: 'text',
                label: 'Bestelling code',
                placeholder: '#123456',
                model: 'code',
              },
            ],
          },
          model: {
            code: null,
          },
        },
        formOptions: {
          validateAfterLoad: false,
          validateAfterChanged: true,
          validateAsync: true,
        },
        user: {},
        orderGroup: {},
        headers: [
          {
            text: 'Product',
            value: 'name',
          },
          {
            text: 'Aantal',
            value: 'count',
          },
          {
            text: 'Prijs',
            sortable: false,
            value: 'price',
          },
          {
            text: 'Vak',
            value: 'section',
          },
          // {
          //   text: 'Status',
          //   value: 'status',
          // },
        ],
        orders: [],
        loading: true,
        alert: {
          visible: false,
          description: '',
          button: {
            visible: false,
            text: null,
            cl: null,
          },
        },
        date: '...',
        clientCoords: null,
      }
    },
    mounted () {
      this.getClientLocation()
    },
    watch: {
      clientCoords: function (val) {
        if (this.clientCoords) {
          this.alert.visible = false
          this.alert.button.visible = false
        } else {
          this.alert.visible = true
          this.alert.description = 'Graag u locatie delen om google maps route te krijgen'
          this.alert.button.visible = true
          this.alert.button.text = 'Locatie delen'
          this.alert.button.icon = 'fa-regular fa-location-dot mr-2 fa-2x'
          this.alert.button.cl = this.getClientLocation
        }
      },
    },
    methods: {
      formatPrice (number) {
        return new Intl.NumberFormat('nl-NL').format(number)
      },
      getClientLocation () {
        navigator.geolocation.getCurrentPosition((showPosition => {
          this.clientCoords = {
            lat: showPosition.coords.latitude,
            long: showPosition.coords.longitude,
          }
        }))
        if (!this.clientLocation) {
          this.alert.visible = true
          this.alert.description = 'Graag u locatie delen om google maps route te krijgen'
          this.alert.button.visible = true
          this.alert.button.icon = 'fa-regular fa-location-dot mr-2 fa-2x'
          this.alert.button.text = 'Locatie delen'
          this.alert.button.cl = this.getClientLocation
        }
      },
      async setAsOpen () {
        const result = await this.$auth.setGroupStatus(this.OLDCOPY_orderGroup, 'open')
        this.orderGroup.status = 'open';
        if (result) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Product is als open geregistreerd',
          })
        } else {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text: 'Er is iets fout gegaan ',
          })
        }
      },
      async setAsClosed () {
        const result = await this.$auth.setGroupStatus(this.OLDCOPY_orderGroup, 'closed')
        this.orderGroup.status = 'closed';
        if (result) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Product is als afgelevert geregistreerd',
          })
        } else {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text: 'Er is iets fout gegaan ',
          })
        }
      },
      async setAsInterrupted () {
        const result = await this.$auth.setGroupStatus(this.OLDCOPY_orderGroup, 'interrupted')
        this.orderGroup.status = 'interrupted';
        if (result) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: 'Product is als onderbroken geregistreerd',
          })
        } else {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text: 'Er is iets fout gegaan ',
          })
        }
      },
      goToUser () {
        this.$router.push(`/users/${this.user.uid}`)
      },
      async calculatePickTime () {
        const timeOtions = {
          hour: 'numeric',
          minute: 'numeric',
        }
        const timeblock = this.orderGroup.timeBlock;
        if(!timeblock) return this.timeBlockDateTime = 'zo snel mogenlijk'
        const time = await this.$auth.getTimeBlock(timeblock.id).then(Ttimeblock => {
          const block = Ttimeblock[0];
          const startTime = new Date(block.start.seconds * 1000).toLocaleTimeString('nl-NL', timeOtions)
          const endTime = new Date(block.end.seconds * 1000).toLocaleTimeString('nl-NL', timeOtions)
          return startTime + ' tot ' + endTime
        })
        const startDateTime = (new Date(timeblock.start.seconds * 1000)).toLocaleDateString('nl-NL', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        })
        this.timeBlockDate = startDateTime;
        this.timeBlockDateTime = startDateTime + ' om ' + time
      },
      calculatePickDate (timeblock) {
        const options = {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }
        const startDateTime = (new Date(timeblock.start.seconds * 1000)).toLocaleDateString('nl-NL', options)
        return startDateTime

      },
      setASheetCode () {
        this.dialog.loadingBtn = true
        this.$auth.updateOrderGroupCode(this.orderGroup.groupId, this.dialog.model.code)
          .then(() => {
            this.orderGroup.code = this.dialog.model.code
            this.$swal({
              icon: 'success',
              title: 'Success',
              text: 'Code is gewijzigd',
            })
            this.dialog.visible = false
          }).catch(err => {
          console.log(err)
        }).finally(() => {
          this.dialog.loadingBtn = false
        })
      },
    },
  }
</script>
